import React from "react"

import Seo from '../components/Seo'
import Tabs from "../components/Tabs"
import TabContent from "../components/TabContent"
import TextContainer from "../components/TextContainer"
import CourseLayout from "../components/CourseLayout"
import TabsContainer from "../components/TabsContainer"

import * as data from "../courseData"

export default function Course() {
  const { title, hours, price, name, id } = data.advanced
  return (
    <CourseLayout
      title={title.main}
      subtitle={title.second}
      hours={hours}
      grossPrice={price.gross}
      newGrossPrice={price.new_gross}
      netPrice={price.net}
      name={name}
      id={id}
    >
      <Seo title="Kurs księgowości dla zaawansowanych" description={`Kurs samodzielnego księgowego we Wrocławiu od ${price.gross} zł.`} />
      <TextContainer>
        <h3>Kurs księgowości dla zaawansowanych</h3>
        <p>
          Kurs samodzielny księgowy adresowany jest do osób, które planują
          rozszerzyć swoją wiedzę z zakresu rachunkowości potrzebną do
          efektywnej pracy w pionach finansowo-księgowych przedsiębiorstw. Na
          kursie samodzielny księgowy zostaną przedstawione zagadnienia związane
          z transakcjami wewnątrzunijnymi, związane z tym rozliczenia podatku
          VAT oraz obowiązki sprawozdawcze.
        </p>
        <p>
          Zakres kursu obejmuje również rozliczenia pomiędzy podmiotami
          gospodarczymi w walutach obcych , gospodarkę materiałową i towarową
          oraz różne podziały kosztów. Uczestnicy nabędą również umiejętność
          sporządzenia polityki rachunkowości firmy, bilansu, rachunku zysków i
          strat oraz sprawozdania finansowego na zakończenie roku obrotowego.
        </p>
        <h3>Dla kogo?</h3>
        <ul>
          <li>planujesz rozszerzyć swoją wiedzę z zakresu rachunkowości</li>
          <li>chcesz samodzielnie prowadzić księgowość firmy</li>
          <li>
            chcesz efektywnie pracować w pionach finansowo-księgowych
            przedsiębiorstw
          </li>
          <li>
            chcesz poznać zagadnienia związane z transakcjami wewnątrzunijnymi
          </li>
          <li>
            chcesz dokonywać rozliczań pomiędzy podmiotami w walutach obcych
          </li>
          <li>chcesz rozliczać firmę produkcyjną</li>
          <li>chcesz poznać zasady tworzenia polityki rachunkowości firmy</li>
          <li>chcesz sporządzać sprawozdania finansowe</li>
        </ul>

        <h3>Program Symfonia – ćwiczenia praktyczne</h3>
        <p>
          Wszystkie wykłady zostaną poparte praktycznymi przykładami oraz pracą
          w programie Finansowo-Księgowym “Symfonia”, które stanowią połowę
          czasu trwania kursu. Ćwiczenia praktyczne polegają na pracy z
          programem Finansowo-Księgowym “Symfonia” oraz “Symfonia” środki
          trwałe. Zajęcia komputerowe swym zakresem obejmują założenie firmy o
          profilu produkcyjnym w programie FK „Symfonia” oraz księgowanie
          różnych operacji gospodarczych w okresie dwóch miesięcy. Księgowania
          odbywają się na podstawie sporządzonych na zajęciach dokumentów oraz
          otrzymanych materiałów ćwiczeniowych. Dokumenty dotyczą typowych
          zdarzeń gospodarczych występujących w firmach oraz wyjątków
          spotykanych w księgowości. Każdy uczestnik kursu pracuje na osobnym
          komputerze przez cały czas trwania kursu. Przy użyciu rzutnika
          prowadzący zajęcia zapoznaje uczestników z programami księgowymi
          używanymi na zajęciach oraz pokazuje sposób księgowania danej operacji
          gospodarczej, którą wszyscy księgują na swoich komputerach. Po
          zaksięgowaniu miesiąca uczestnicy sprawdzają prawidłowość swoich
          księgowań, poprawiają błędy oraz sporządzają różnego rodzaju
          zestawienia oraz sprawozdania finansowe.
        </p>
        <TabsContainer>
          <Tabs elements={["Program kursu", "Harmonogram kursu"]}>
            <TabContent index={0}>
              <h3>Program kursu samodzielny księgowy</h3>
              <ul>
                <li>
                  Podatek VAT transakcje unijne, WDT, WNT, usługi świadczone w
                  UE
                </li>
                <li>Import, eksport towarów i usług</li>
                <li>Rozliczenia walutowe, różnice kursowe</li>
                <li>Szczególne momenty powstania obowiązku podatkowego</li>
                <li>
                  Rezerwy i rozliczenia międzyokresowe kosztów i przychodów
                </li>
                <li>Obrót materiałowy i towarowy</li>
                <li>Metody wyceny rozchodów rzeczowych aktywów obrotowych</li>
                <li>Rozliczenie produkcji</li>
                <li>Dokumentacja opisująca politykę rachunkowości</li>
                <li>Klasyfikacja i ewidencja umów leasingowych</li>
                <li>Układ rodzajowy i kalkulacyjny kosztów</li>
                <li>Bilans</li>
                <li>Rachunek wyników wariant porównawczy i kalkulacyjny</li>
                <li>Roczne sprawozdanie finansowe</li>
              </ul>
            </TabContent>
            <TabContent index={1}>
              <h3>Harmonogram kursu</h3>
              <h5>Zajęcia nr 1 i 2</h5>
              <p>
                Tematy zajęć: powtórzenie wybranych zagadnień z zakresu
                rachunkowości, szczególne momenty powstania obowiązku
                podatkowego VAT, Wewnątrzwspólnotowa dostawa towarów,
                wewnątrzwspólnotowe nabycie towarów, import, sporządzanie
                deklaracji oraz korekt deklaracji VAT, delegacje krajowe i
                zagraniczne, rozrachunki walutowe, ćwiczenia w programie
                „Symfonia”.
              </p>
              <h5>Zajęcia nr 3 i 4</h5>
              <p>
                Tematy zajęć: gospodarka materiałowa w firmie
                produkcyjno-handlowej, rozliczanie magazynów, odchylenia od cen
                ewidencyjnych, rozliczenia międzyokresowe kosztów zakupu,
                wprowadzanie w programie „Symfonia” faktur zakupu i sprzedaży,
                raportów kasowych, wyciągów bankowych złotówkowych i walutowych.
              </p>
              <h5>Zajęcia nr 5 i 6</h5>
              <p>
                Tematy zajęć: rozliczenie produkcji, produkcja w toku,
                rozrachunki z tytułu należności i zobowiązań wobec kontrahentów,
                pracowników, US, ZUS, naliczanie odsetek, rezerwy i odpisy
                aktualizacyjne, RMK bierne i czynne, ćwiczenia „Symfonia”.
              </p>
              <h5>Zajęcia nr 7 i 8</h5>
              <p>
                Tematy zajęć: zamknięcie miesiąca, rachunek zysków i strat,
                bilans, , przygotowanie do zamknięcia roku, inwentaryzacja,
                sprawozdanie finansowe, polityka rachunkowości, zysk bilansowy a
                zysk podatkowy, ćwiczenia „Symfonia”.
              </p>
              <h5>Zajęcia nr 9 i 10</h5>
              <p>
                Tematy zajęć: powtórzenie wiadomości , egzamin –zadania do
                samodzielnego wykonania w programie „Symfonia”.
              </p>
            </TabContent>
          </Tabs>
        </TabsContainer>
      </TextContainer>
    </CourseLayout>
  )
}
